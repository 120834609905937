import { Hero } from "components/Hero";
import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import Helmet from "react-helmet";

export default function PrivateHirePage({ location }) {
  const title = "Private Hire | Plush Oxford";
  const description =
    "Plush offers the perfect private hire venue for your event, party, or special occasion. We offer prefabbed booking packages or customised deals.";

  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "en-GB" }}
        title={title}
        meta={[
          { name: "description", content: description },
          { property: "og:url", content: location.href },
          { property: "og:title", content: title },
          { property: "og:description", content: description },
          { property: "og:image", content: "" },
          { name: "facebook-domain-verification", content: "" },
        ]}
      />
      <Hero
        background={<StaticImage src="../images/heroPrivateHire.jpeg" alt="" />}
      >
        <h1>Private Hire</h1>
      </Hero>
      <section className="private_hire">
        <h2>Private Hire</h2>
        <p>
          Plush offers the perfect private hire venue for your event, party, or
          special occasion. We offer prefabbed booking packages or customised
          deals.
        </p>
        <p>
          Plush regularly hosts bops for Oxford university colleges, drag acts,
          music events, receptions and functions for local businesses, Christmas
          do's, and one-off (or regular!) events for those who just want to
          throw a good party.
        </p>
        <p>
          Through the entire process, our aim is to ensure that booking Plush is
          as hassle-free and flexible as possible; and then, during the event,
          to host the perfect party where all guests are afforded best possible
          experience. In short, we want you to be happy whilst making the
          booking arrangements, during the event, and after (as you'll probably
          be nursing a hangover!).
        </p>
        <p>
          For more information, please email{" "}
          <a className="animated_link" href="mailto:director@plushoxford.com">
            director@plushoxford.com
          </a>{" "}
          and one of the Plush team will be happy to help!
        </p>
      </section>
    </>
  );
}
